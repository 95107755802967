import { useLazyQuery } from '@apollo/client';
import { PremisesResponse } from '../models/queries/QueryResponses';
import { InfoQueryVars } from '../models/InfoTV';
import { GET_PREMISES } from '../graphql/queries';
import { useEffect } from 'react';
import { trimAndSort } from '../utils/helperFunctions';

export function useGetPremises(locationId: string | undefined) {
	const [getPremiseData, { data: premises_data }] = useLazyQuery<PremisesResponse, InfoQueryVars>(GET_PREMISES);

	useEffect(() => {
		if (!locationId) return;
		getPremiseData({ variables: { locationId: locationId } });
	}, [getPremiseData, locationId]);

	return { premises: premises_data ? trimAndSort(premises_data.premises) : [] };
}
