import { useEffect, useState } from 'react';
import { BottomNavigationBar } from './components/bottom-navbar/BottomNavigationBar';
import { useGetMeasurement } from './hooks/useGetMeasurement';
import { useGetQuestionnaire } from './hooks/useGetQuestionnaire';

import { Header } from './components/header/Header';
import { useWindowResize } from './hooks/useWindowResize';
import { ActionView } from './models/enum/ActionView';
import { setCodeExists, setIsDesktop, useSession } from './stores/SessionStore';
import { ApplicationWrapperContainer } from './styles/styled-components/containers';
import { DashboardView } from './views/dashboard/DashboardView';
import { MeasurementsView } from './views/measurements/MeasurementsView';
import { QuestionnaireView } from './views/questionnaire/QuestionnaireView';
import { isServiceRequestEnabled, ServiceRequestView } from './views/serviceRequest/ServiceRequestView';
import { NoContent } from './views/error/NoContent';

export function ApplicationWrapper() {
	const { state, dispatch } = useSession();
	const [activeView, setActiveView] = useState<ActionView>(ActionView.Dashboard);

	useGetQuestionnaire();
	useGetMeasurement(state.premiseId);

	const { windowWidth } = useWindowResize();
	const isDesktop = windowWidth >= 1024;
	useEffect(() => {
		dispatch(setCodeExists(undefined));
		dispatch(setIsDesktop(isDesktop));
	}, [dispatch, isDesktop]);

	if (!state.dashboard) return <NoContent />;

	return (
		<>
			<Header
				activePalette={state.activePalette}
				isDesktop={isDesktop}
				locationName={state.dashboard?.locationName}
				premiseName={state.dashboard?.premiseName}
				showLanguagePicker={true}
			/>
			<ApplicationWrapperContainer>
				{activeView === ActionView.Dashboard && <DashboardView />}
				{/* Here we should conditionally render ServiceRequestView or MeasurementsView on tab index 1 depending if the location has the granlund sync on */}
				{activeView === ActionView.Measurements && <MeasurementsView />}
				{activeView === ActionView.Questionnaire && <QuestionnaireView />}
				{activeView === ActionView.Servicerequest && <ServiceRequestView services={state.externalServices} />}
			</ApplicationWrapperContainer>
			<BottomNavigationBar
				activeView={activeView}
				setActiveView={setActiveView}
				isServiceRequestEnabled={isServiceRequestEnabled(state.externalServices)}
				activePalette={state.activePalette}
			/>
		</>
	);
}
