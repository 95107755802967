import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { ReactComponent as TemperatureIcon } from '../../../images/temp.svg';
import { ActivePalette } from '../../../models/context/Session';
import { freesiColors, solColors } from '../../../styles/globalStyles';
import { FullSizeBlock } from '../../../styles/styled-components/containers';
import { tempIconStyle } from '../../../styles/styled-components/icons';
import { FlexRow } from '../../../styles/styled-components/rows';
import { MeasurementTitle, MeasurementValue, TemperatureInfo } from '../../../styles/styled-components/text';

interface TileProps {
	data: number;
	activePalette: ActivePalette;
}

export function TemperatureTile({ data, activePalette }: TileProps) {
	const { t } = useTypedTranslation();

	return (
		<FullSizeBlock palette={activePalette}>
			<TemperatureIcon
				style={tempIconStyle}
				fill={activePalette === ActivePalette.SOL ? solColors.solDarkYellow : freesiColors.iisyPurple}
			/>
			<FlexRow
				style={{
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
				<MeasurementTitle palette={activePalette}>{t('core.indoor-temp')}</MeasurementTitle>
				<MeasurementValue type={data ? '' : 'no-data'} palette={activePalette}>
					{data ? `${data.toFixed(1)}°C` : t('temperature.no-data')}
				</MeasurementValue>
				{data && <TemperatureInfo palette={activePalette}>{t('core.building-avg')}</TemperatureInfo>}
			</FlexRow>
		</FullSizeBlock>
	);
}
