import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { GET_INFOTV } from '../graphql/queries';
import { InfoQueryVars } from '../models/InfoTV';
import { InfoResponse } from '../models/queries/QueryResponses';
import { isEmpty } from 'lodash';

const THIRTY_MINUTES = 1800000;

export function useGetInfoTvData(locationId: string) {
	const [getData, { loading, data, error }] = useLazyQuery<InfoResponse, InfoQueryVars>(GET_INFOTV, {
		variables: { locationId }
	});

	const fetchData = useCallback(() => {
		if (typeof locationId === 'string' && !isEmpty(locationId)) {
			getData();
		}
	}, [locationId, getData]);

	useEffect(() => {
		fetchData();

		// Fetch updated information about the location from the server every 30 minutes.
		const intervalId = setInterval(fetchData, THIRTY_MINUTES);

		// Cleanup: Clear the interval when the component unmounts
		return () => clearInterval(intervalId);
	}, [locationId, fetchData]);

	return { data, loading, error };
}
