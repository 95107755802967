import { useEffect, useState } from 'react';
import { NewPremise } from '../models/context/Install';
import { setDisableState, setNewPremise, useInstall } from '../stores/InstallStore';

export function useCreatePremise() {
	const { dispatch } = useInstall();

	const [createdPremise, setCreatedPremise] = useState<NewPremise>({
		namePrefix: '',
		nameSuffix: '',
		type: { id: '', name: { en: '', fi: '', sv: '', de: '', fr: '', es: '' } },
		floor: 1
	});

	useEffect(() => {
		if (
			createdPremise &&
			createdPremise.nameSuffix.length &&
			createdPremise.type.id.length &&
			typeof createdPremise.floor === 'number'
		) {
			dispatch(setDisableState(false));
			dispatch(setNewPremise(createdPremise));
		} else {
			dispatch(setDisableState(true));
		}
	}, [createdPremise, dispatch]);

	return { createdPremise, setCreatedPremise };
}
