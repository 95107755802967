import { ExternalServices, Service } from '../../models/queries/QueryResponses';
import { isNil } from '../../utils/typeGuards';
import { GranlundManagerServiceRequestView } from './GranlundManagerServiceRequestView';

export type ServiceRequestConfig = {
	config: string;
	pin: string | undefined;
};

export enum ServiceRequestType {
	GranlundManager = 'GranlundManager service request'
}

const supportedServices: string[] = [ServiceRequestType.GranlundManager];

export const isServiceRequestEnabled = (services: ExternalServices | undefined): boolean => {
	if (isNil(services)) return false;

	const premiseServices = services.servicesPremise;
	const locationServices = services.servicesLocation;
	return (
		(!isNil(premiseServices) && premiseServices.some(svc => supportedServices.includes(svc.name))) ||
		(!isNil(locationServices) && locationServices.some(svc => supportedServices.includes(svc.name)))
	);
};

const effectiveService = (services: ExternalServices): Service | undefined => {
	return (
		services.servicesPremise.find(svc => supportedServices.includes(svc.name)) ??
		services.servicesLocation.find(svc => supportedServices.includes(svc.name))
	);
};

type ServiceRequestProps = {
	services: ExternalServices | undefined;
};

export function ServiceRequestView({ services }: ServiceRequestProps) {
	if (isNil(services)) return null;
	const isEnabled = isServiceRequestEnabled(services);
	if (!isEnabled) return null;
	const service = effectiveService(services);
	if (isNil(service)) return null;

	return (
		<>
			{service.name === ServiceRequestType.GranlundManager && (
				<GranlundManagerServiceRequestView serviceId={service.id} config={service.config} checkPinCode={service.pin} />
			)}
		</>
	);
}
