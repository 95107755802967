import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLocaleStrings } from './i18n/getLocaleStrings';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			fi: { translation: getLocaleStrings('fi') },
			en: { translation: getLocaleStrings('en') },
			sv: { translation: getLocaleStrings('sv') },
			de: { translation: getLocaleStrings('de') },
			fr: { translation: getLocaleStrings('fr') },
			es: { translation: getLocaleStrings('es') }
		},
		fallbackLng: 'en',
		debug: process.env.REACT_APP_I18N_DEBUG === 'true',
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
