import fi from '../locales/fi.json';
import en from '../locales/en.json';
import sv from '../locales/sv.json';
import de from '../locales/de.json';
import fr from '../locales/fr.json';
import es from '../locales/es.json';
import fi_dev from '../locales/fi_dev.json';
import sv_dev from '../locales/sv_dev.json';
import de_dev from '../locales/de_dev.json';
import fr_dev from '../locales/fr_dev.json';
import es_dev from '../locales/es_dev.json';

import { z } from 'zod';
import { deepMerge } from '../utils/deepMerge';

export const supportedLocaleSchema = z.enum(['en', 'fi', 'sv', 'de', 'fr', 'es']);

export type SupportedLocale = z.infer<typeof supportedLocaleSchema>;
export type LocaleStrings = typeof en;
export const defaultLocale: SupportedLocale = 'en';
export const supportedLocales: SupportedLocale[] = supportedLocaleSchema.options;
export const nonDefaultLocales: SupportedLocale[] = supportedLocales.filter(l => l !== defaultLocale);

/**
 * Merges "official" translations with the unchecked dev versions of strings
 *
 * @See assertLocalizationKeys.test.ts
 */
export function getLocaleStrings(locale: SupportedLocale): LocaleStrings {
	switch (locale) {
		case 'en':
			return en;
		case 'fi':
			// getting compile errors when locales are missing is annoying - assertLocalizationKeys.test.ts
			// will still make sure the translations are present
			return deepMerge(fi, fi_dev) as any as LocaleStrings;
		case 'sv':
			return deepMerge(sv, sv_dev) as any as LocaleStrings;
		case 'de':
			return deepMerge(de, de_dev) as any as LocaleStrings;
		case 'fr':
			return deepMerge(fr, fr_dev) as any as LocaleStrings;
		case 'es':
			return deepMerge(es, es_dev) as any as LocaleStrings;
		default:
			throw new Error('Unsupported locale:' + locale);
	}
}
