import { useEffect } from 'react';
import { Header } from '../../components/header/Header';
import { MobileTempTile } from '../../components/tile/temperature/MobileTempTile';
import { TemperatureTile } from '../../components/tile/temperature/TemperatureTile';
import { DelaySpinner } from '../../components/util/DelaySpinner';
import { useGetInfoTvData } from '../../hooks/useGetInfoTvData';
import { useWindowResize } from '../../hooks/useWindowResize';
import { setIsDesktop, useSession } from '../../stores/SessionStore';
import { FlexContainer, InfoTvContainer } from '../../styles/styled-components/containers';
import { InfoMeasurementColumn } from './InfoMeasurementColumn';

export function InfoView() {
	const { state, dispatch } = useSession();
	const { data, loading, error } = useGetInfoTvData(state.locationId);

	const { windowWidth } = useWindowResize();
	const isDesktop = windowWidth >= 1024;

	useEffect(() => {
		dispatch(setIsDesktop(isDesktop));
	}, [dispatch, isDesktop]);

	if (loading) return <DelaySpinner delay={30} />;

	// TODO: implement proper error view
	const errorRender = () => {
		return <div>No data received</div>;
	};

	if (error || !data) return errorRender();

	return (
		<FlexContainer>
			<Header
				activePalette={state.activePalette}
				isDesktop={isDesktop}
				showLanguagePicker={false}
				locationName={data.infoTV.locationName}
			/>
			<InfoTvContainer mobile={!isDesktop}>
				{data.infoTV.temperature && isDesktop && (
					<TemperatureTile data={data.infoTV.temperature} activePalette={state.activePalette} />
				)}
				{data.infoTV.temperature && !isDesktop && <MobileTempTile data={data.infoTV.temperature} />}
				<InfoMeasurementColumn
					isDesktop={state.isDesktop}
					activePalette={state.activePalette}
					data={data}
					locationType={state.locationType}></InfoMeasurementColumn>
			</InfoTvContainer>
		</FlexContainer>
	);
}
